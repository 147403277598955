import React, { useEffect } from 'react'

import { programs } from '../../Files/programs';

import './styles.scss'

const Programs = () => {
    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navprograms");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);
    return (
        <div className='programs'>
            <h1>Our Programs</h1>
            <div className='programs-container'>
                {programs.map((program) => (
                    <div className='program-card' key={program.id}>
                        <h2>{program.title}</h2>
                        <p>Session Date: {program.sessionDate}</p>
                        <p>Month: {program.month}</p>
                    </div>
                ))}
            </div   >
        </div>
    )
}

export default Programs