import React, { useEffect, useState } from 'react';
import './navbar.scss';

const Navigator = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                document.querySelector('.navbar').classList.add('navbar--scroll');
            } else {
                document.querySelector('.navbar').classList.remove('navbar--scroll');
            }
        });
    }, []);

    // add class to navbar based on route
    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/' || path === '/products/lumolearn') {
            document.querySelector('.navbar').classList.add('navbar--home');
        } else {
            document.querySelector('.navbar').classList.remove('navbar--home');
        }
    }, []);


    return (
        <>
            <div className='navbar'>
                <div className='logo'>
                    <a href='/'>
                        <img src={require('../Assets/logo.webp')} alt='Logo' />
                    </a>
                </div>
                <div className='nav-links'>
                    <a id='navhome' href='/'>Home</a>
                    <a id='navprograms' href='/programs'>Our Programs</a>
                    <a id='navhelp' href='/help'>Help</a>
                    <a id='navregister' href='/register'>Register</a>
                    <a id='login' className='loginbutton' href='/signin'>Login</a>
                </div>
            </div>

            <div className='mobile-nav-toggle'>
                <div className='logo'>
                    <img src={require('../Assets/logo.webp')} alt='Logo' />
                </div>

                <div className='hamburger'
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <button class={
                        isMenuOpen ? " menu__icon active-menu" : "menu__icon"
                    }>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>

            <div
                className={isMenuOpen
                    ? "mobile-nav-container-active"
                    : 'mobile-nav-container'}
            >
                <div className='mobile-nav-box'>
                    <div className='mobile-nav-links'>
                        <a id='navhome' href='/'>Home</a>
                        <a id='navprograms' href='/programs'>Our Programs</a>
                        <a id='navhelp' href='/help'>Help</a>
                        <a id='navregister' href='/register'>Register</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navigator;
