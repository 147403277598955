import React from 'react'

import './styles.scss'
import { Button, Form, Input, notification } from 'antd'

import loginsData from '../../Files/logins'

const Signin = () => {
    const [api, contextHolder] = notification.useNotification();

    const onFinish = (values) => {
        const { username, password } = values

        const login = loginsData.find(login => login.email === username && login.password === password)

        if (login) {
            api.success({
                message: 'Login Success',
                description: 'You have successfully logged in'
            })
            localStorage.setItem('login-codeclubs', JSON.stringify(login))
            window.location.href = '/admin'
        } else {
            api.error({
                message: 'Login Failed',
                description: 'Please check your username and password'
            })
        }
    }

    return (
        <div className='signin'>
            {contextHolder}
            <div className='container'>
                <div className='signin-header'>
                    <h1>
                        Login
                    </h1>

                    <p>
                        Login to your account for more features
                    </p>
                </div>
                <div className='form'>

                    <Form
                        layout='vertical'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Signin