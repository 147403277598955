import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { collection, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../firebase'
import { codeclubs } from '../../../Files/centers'
import { Button, Input, message, Popconfirm, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const NewRegistartions = () => {
    const [loading, setLoading] = useState(false)
    const [newRegistrations, setNewRegistrations] = useState([])
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [approveLoading, setApproveLoading] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [rejectLoading, setRejectLoading] = useState("");


    useEffect(() => {
        handleGetNewRegistrations()
    }, [])

    const handleGetNewRegistrations = async () => {
        setLoading(true);
        try {
            let newRegistrations = [];
            const q = query(
                collection(db, 'registrations'),
                where('isApproved', '==', 'Pending')
            );

            const registrationsSnapshot = await getDocs(q);

            registrationsSnapshot?.forEach(doc => {
                const registration = doc.data();
                registration.id = doc.id;
                newRegistrations.push(registration);
            });

            newRegistrations.sort((a, b) => {
                const dateA = a.date?.split('/').reverse().join('');
                const dateB = b.date?.split('/').reverse().join('');
                return dateB - dateA;
            });

            setNewRegistrations(newRegistrations);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleApproveStudent = async (id) => {
        setApproveLoading(id);
        try {
            const studentRef = doc(db, 'registrations', id);

            await updateDoc(studentRef, {
                isApproved: 'Approved'
            });
            messageApi.open({
                type: 'success',
                content: `Student ${newRegistrations.find(s => s.id === id).childName} has been approved`
            })
            setNewRegistrations(newRegistrations.filter(s => s.id !== id));

        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: `Failed to approve student ${newRegistrations.find(s => s.id === id).childName}`
            })
        }
        setApproveLoading(id);
    };

    const handleRejectStudent = async (id) => {
        setRejectLoading(id);
        try {
            const studentRef = doc(db, 'registrations', id);
            await updateDoc(studentRef, {
                isApproved: 'Rejected'
            });
            messageApi.open({
                type: 'success',
                content: `Student ${newRegistrations.find(s => s.id === id).childName} has been rejected`
            })
            setNewRegistrations(newRegistrations.filter(s => s.id !== id));
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',

                content: `Failed to reject student ${newRegistrations.find(s => s.id === id).childName}`
            })
        }
        setRejectLoading(id);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'Child Name',
            dataIndex: 'childName',
            key: 'childName',
            width: '20%',
            ...getNameSearchProps('childName'),
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentsName',
            key: 'parentsName',
            width: '20%',
            ...getNameSearchProps('parentsName'),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: '10%',
        },
        {
            title: "Parent's Contact",
            dataIndex: 'parentsContact',
            key: 'parentsContact',
            width: "10%",
        },
        {
            title: "Code Club",
            dataIndex: 'center',
            key: 'center',
            width: '20%',
            render: (center) => codeclubs.find(c => c.id === center)?.name
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '15%',
            sorter: (a, b) => {
                const parseDate = (dateStr) => {
                    const [day, month, year] = dateStr.split('/').map(Number);
                    return new Date(year, month - 1, day);
                };

                const dateA = parseDate(a.date);
                const dateB = parseDate(b.date);

                return dateB - dateA;
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <div className='action_Buttons'>
                    <Button
                        type='primary'
                        onClick={() => handleApproveStudent(id)}
                        loading={approveLoading === id}
                    >
                        Approve
                    </Button>

                    <Popconfirm
                        title="Are you sure to delete this student?"
                        onConfirm={() => handleRejectStudent(id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type='primary'
                            danger
                        >
                            Reject
                        </Button>
                    </Popconfirm>
                </div>
            )
        }
    ];

    return (
        <div className='new_registartion'>
            {contextHolder}
            <h1>
                New Registrations
            </h1>

            <Table
                className='table'
                loading={loading}
                columns={columns}
                dataSource={newRegistrations}
            />
        </div>
    )
}

export default NewRegistartions