import React from 'react'

import './styles.scss'

const PrivacyPolicy = () => {
    return (
        <div className='privacy_policy'>
            <h1>
                Privacy Policy
            </h1>
        </div>
    )
}

export default PrivacyPolicy