import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import './styles.scss'
import { Button, Divider, Form, Input, Radio, Select, Space, Spin, notification } from 'antd'

import { collection, addDoc, getDocs } from 'firebase/firestore'

import { codeclubs } from '../../Files/centers'
import { db } from '../../firebase'

const centerOptions = codeclubs.map((center) => {
    return {
        label: center.name,
        value: center.id
    }
})


const Register = () => {
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    // Active link
    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navregister");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    const onFinish = async (values) => {
        setLoading(true)

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        try {
            let isAlreadyRegistered = false;
            // const querySnapshot = await getDocs(collection(db, "registrations"));
            // querySnapshot.forEach((doc) => {
            //     if (doc.data().childName === values.childName) {
            //         isAlreadyRegistered = true;
            //         api.error({
            //             message: 'Registration Failed',
            //             description: 'A registration for the same child already exists. Please contact us for more information.'
            //         });
            //     }
            // });

            if (isAlreadyRegistered) {
                setLoading(false)
                return;
            } else {
                const id = uuidv4()

                await addDoc(collection(db, "registrations"), {
                    id: id,
                    center: values.nearesrclub,
                    parentsName: values.parentsName,
                    parentsContact: values.parentsContact,
                    address: values.address,
                    age: values.age,
                    childName: values.childName,
                    gender: values.gender,
                    attendance: [],
                    isApproved: "Pending",
                    date: new Date().toLocaleDateString()
                });
                api.success({
                    message: 'Registration Successful',
                    description: 'You have successfully registered for the CoderDojo. You will receive a confirmation SMS or email once your registration is approved.'
                });

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }

        } catch (e) {
            console.error("Error adding document: ", e);
            api.error({
                message: 'Registration Failed',
                description: 'There was an error while registering. Please try again later or contact us.'
            });
        }

        setLoading(false)
    }
    return (
        <div className='regsiter'>
            {contextHolder}
            <div className='container'>
                <h1>Student Registration</h1>

                <h2>
                    You will receive a confirmation SMS or email once your registration is approved.
                    <br />
                    ඔබගේ ලියාපදිංචිය අනුමත වූ පසු ඔබට තහවුරු කිරීමේ SMS හෝ විද්‍යුත් තැපෑලක් ලැබෙනු ඇත.
                    <br />
                    உங்கள் பதிவு அங்கீகரிக்கப்பட்டதும் உறுதிப்படுத்தல் SMS அல்லது மின்னஞ்சலைப் பெறுவீர்கள்.
                </h2>

                <Divider />
                <div className='form'>
                    <Spin spinning={loading} tip='Please Wait...'>
                        <Form
                            layout='vertical'
                            onFinish={onFinish}
                            autoComplete='off'
                        >
                            <Form.Item
                                label={<p>Select your nearest CoderDojo<br />ඔබට ළගම පිහිටි මධ්‍යස්ථානය තෝරාගන්න | உங்களுக்கு அருகில் உள்ள மையத்தைத் தேர்ந்தெடுக்கவும்</p>}
                                name="nearesrclub"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your nearest CoderDojo'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    maxLength={1}
                                    placeholder="Select your nearest CoderDojo"
                                    options={centerOptions}
                                />
                            </Form.Item>

                            <Form.Item
                                label={<p>Name of the Parent<br />දෙමාපියන්ගේ නම | பெற்றோரின் பெயர்</p>}
                                name="parentsName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input name of the Parent'
                                    },
                                ]}
                            >
                                <Input placeholder='Enter your name' showCount maxLength={50} />
                            </Form.Item>

                            <Form.Item
                                label={<p>Contact Number of the Parent<br />දෙමාපියන්ගේ දුරකථන අංකය | பெற்றோரின் தொலைபேசி எண்</p>}
                                name="parentsContact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input contact number of the parent',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter parent's contact number" showCount maxLength={10} />
                            </Form.Item>

                            <Form.Item
                                label={<p>Postal Address<br />තැපැල් ලිපිනය | அஞ்சல் முகவரி</p>}
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input postal address!',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter postal address" showCount maxLength={100} />
                            </Form.Item>

                            <Form.Item
                                label={<p>Name of the Child (First and Last Name)<br />දරුවාගේ නම | குழந்தையின் பெயர்</p>}
                                name="childName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input name of the child!',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter child's name" showCount maxLength={50} />
                            </Form.Item>

                            <Form.Item
                                label={<p>Gender of the Child<br />දරුවාගේ ලිංගභේදය | குழந்தையின் பாலினம்</p>}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select gender of the child!',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value='male'>Male | පිරිමි | ஆண்</Radio>
                                        <Radio value='female'>Female | ගැහැණු | பெண்</Radio>
                                        <Radio value='null'>Prefer not to say | නොකියන්න කැමති | சொல்லாமல் இருக்க விருப்பம்</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label={<p>Age of the Child (Years)<br />දරුවාගේ වයස (අවුරුදු) | குழந்தையின் வயது (ஆண்டுகள்)</p>}
                                name="age"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input age of the child!',
                                    },
                                ]}
                            >
                                <Input type='number' placeholder="Enter child's age" max={18} min={5} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Register
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default Register